// @codekit-prepend "utils.js"
// @codekit-prepend "bundle/instant.page.js"

/*!
 * swiped-events.js - v1.0.9
 * Pure JavaScript swipe events
 * https://github.com/john-doherty/swiped-events
 * @inspiration https://stackoverflow.com/questions/16348031/disable-scrolling-when-touch-moving-certain-element
 * @author John Doherty <www.johndoherty.info>
 * @license MIT
 */
!(function(t, e) {
	"use strict";
	"function" != typeof t.CustomEvent &&
		((t.CustomEvent = function(t, n) {
			n = n || { bubbles: !1, cancelable: !1, detail: void 0 };
			var u = e.createEvent("CustomEvent");
			return u.initCustomEvent(t, n.bubbles, n.cancelable, n.detail), u;
		}),
		(t.CustomEvent.prototype = t.Event.prototype)),
		e.addEventListener(
			"touchstart",
			function(t) {
				if ("true" === t.target.getAttribute("data-swipe-ignore"))
					return;
				(o = t.target),
					(l = Date.now()),
					(n = t.touches[0].clientX),
					(u = t.touches[0].clientY),
					(a = 0),
					(i = 0);
			},
			!1
		),
		e.addEventListener(
			"touchmove",
			function(t) {
				if (!n || !u) return;
				var e = t.touches[0].clientX,
					l = t.touches[0].clientY;
				(a = n - e), (i = u - l);
			},
			!1
		),
		e.addEventListener(
			"touchend",
			function(t) {
				if (o !== t.target) return;
				var e = parseInt(
						o.getAttribute("data-swipe-threshold") || "20",
						10
					),
					s = parseInt(
						o.getAttribute("data-swipe-timeout") || "500",
						10
					),
					r = Date.now() - l,
					c = "";
				Math.abs(a) > Math.abs(i)
					? Math.abs(a) > e &&
					  r < s &&
					  (c = a > 0 ? "swiped-left" : "swiped-right")
					: Math.abs(i) > e &&
					  r < s &&
					  (c = i > 0 ? "swiped-up" : "swiped-down");
				"" !== c &&
					o.dispatchEvent(
						new CustomEvent(c, { bubbles: !0, cancelable: !0 })
					);
				(n = null), (u = null), (l = null);
			},
			!1
		);
	var n = null,
		u = null,
		a = null,
		i = null,
		l = null,
		o = null;
})(window, document);

(function() {
	var ReactiveListener = (window.ReactiveListener = {
		elements: []
	});

	ReactiveListener.add = function(elem, options) {
		var i,
			item = { elem: elem, options: options };
		for (var key in options) {
			if (Array.isArray(options[key])) {
				for (i = 0; i < options[key].length; i++) {
					item.options[key][i].current = options[key][i].start || 0;
				}
			} else {
				item.options[key].current = options[key].start || 0;
			}
		}
		// TODO:  I think we'll need to do this at least on resize & scroll,
		// to account for things like fixed positioning.
		item = ReactiveListener.addLocationAndDimensions(item);
		ReactiveListener.elements.push(item);
	};

	ReactiveListener.addLocationAndDimensions = function(item) {
		// TODO:  Refactor window pieces so we can loop over elems without recalculating
		var rect = item.elem.getBoundingClientRect(),
			winY = window.pageYOffset,
			winX = window.pageXOffset;

		item.box = {
			left: rect.left + winX,
			right: rect.left + rect.width + winX,
			top: rect.top + winY,
			bottom: rect.top + winY + rect.height
		};
		return item;
	};

	// TODO:  Look up standards
	ReactiveListener.setMouseProperties = function(event) {
		ReactiveListener.mouse = {
			x: event.x,
			y: event.y,
			pageX: event.pageX,
			pageY: event.pageY
		};
	};

	ReactiveListener.handleMouseMove = function(event) {
		// TODO:  Debounce
		ReactiveListener.setMouseProperties(event);
		window.requestAnimationFrame(ReactiveListener.render);
	};

	function ease(current, target, ease) {
		if (Math.abs(target - current) < (ease || 0.5)) {
			return target;
		}
		return current + (target - current) * (ease || 0.05);
	}

	function distVal(x, y) {
		return Math.sqrt(x * x + y * y);
	}

	function calculateTargetAndEase(transform, trigger, dist) {
		var near = transform.range[0],
			far = transform.range[1],
			range = Math.abs(far - near),
			mid = near + far / 2,
			maxDist,
			target,
			easer,
			bounds;
		if (trigger === "PointerX") {
			bounds = transform.maxDist || ReactiveListener.winRect.width;
		} else if (trigger === "PointerY") {
			bounds = transform.maxDist || ReactiveListener.winRect.height;
		} else {
			bounds =
				transform.maxDist ||
				distVal(
					ReactiveListener.winRect.height,
					ReactiveListener.winRect.width
				);
		}

		if (!transform.directional) {
			dist = Math.abs(dist);
		}

		if (dist > bounds) {
			target = far;
		} else if (dist < -bounds) {
			target = near;
		} else {
			if (transform.directional) {
				if (dist < 0) {
					target = mid - (near - mid) * (dist / bounds);
				} else if (dist > 0) {
					target = mid + (far - mid) * (dist / bounds);
				} else {
					target = mid;
				}
			} else {
				target = near + (far - near) * (dist / bounds);
			}
		}
		var easer = Math.min(range / 20.0, 0.05);
		return {
			target: target,
			newValue: ease(transform.current, target, easer)
		};
	}

	ReactiveListener.setWindowDims = function() {
		ReactiveListener.winRect = document.body.getBoundingClientRect();
		ReactiveListener.winY = window.pageYOffset;
		ReactiveListener.winX = window.pageXOffset;
	};

	function calcYDist(item) {
		if (ReactiveListener.mouse.pageY > item.box.bottom) {
			return ReactiveListener.mouse.pageY - item.box.bottom;
		} else if (ReactiveListener.mouse.pageY < item.box.top) {
			return ReactiveListener.mouse.pageY - item.box.top;
		} else {
			return 0;
		}
	}
	function calcXDist(item) {
		if (ReactiveListener.mouse.pageX > item.box.right) {
			return ReactiveListener.mouse.pageX - item.box.right;
		} else if (ReactiveListener.mouse.pageX < item.box.left) {
			return ReactiveListener.mouse.pageX - item.box.left;
		} else {
			return 0;
		}
	}

	function calc2dDist(item) {
		return distVal(calcXDist(item), calcYDist(item));
	}

	ReactiveListener.render = function() {
		var i,
			j,
			item,
			box,
			dist,
			trigger,
			changeSet,
			changeStrs,
			change,
			transforms,
			changed = false;
		ReactiveListener.setWindowDims(); // TODO:  Should this go here?  Or only on scroll/resize?
		if (ReactiveListener.mouse) {
			for (i = 0; i < ReactiveListener.elements.length; i++) {
				item = ReactiveListener.elements[i];
				box = item.box;
				dist = {
					PointerX: calcXDist(item),
					PointerY: calcYDist(item),
					Pointer2d: calc2dDist(item)
				};
				changeStrs = {};
				for (trigger in item.options) {
					if (Array.isArray(item.options[trigger])) {
						transforms = item.options[trigger];
					} else {
						transforms = [item.options[trigger]];
					}
					for (j = 0; j < transforms.length; j++) {
						// Thresholds
						if (
							transforms[j].forceMin === false &&
							transforms[j].minDist &&
							transforms[j].minDist > Math.abs(dist[trigger])
						) {
							continue;
						}
						if (
							transforms[j].forceMax === false &&
							transforms[j].maxDist &&
							transforms[j].maxDist < Math.abs(dist[trigger])
						) {
							continue;
						}

						// JS Callback
						if (transforms[j].callback) {
							var resp = transforms[j].callback({
								item: item,
								dist: dist,
								mousePosition: ReactiveListener.mouse
							});
							// if callback returns false, apply no changes to this elem.
							if (resp === false) {
								changeStrs = [];
								break;
							} else {
								continue;
							}
						}
						// Straight CSS properties
						changeSet = calculateTargetAndEase(
							transforms[j],
							trigger,
							dist[trigger]
						);
						transforms[j].current = changeSet.newValue;
						if (transforms[j].property === "transform") {
							changeStrs["transform"] =
								changeStrs["transform"] || "";
							changeStrs["transform"] =
								changeStrs["transform"] +
								transforms[j].transform +
								"(" +
								changeSet.newValue +
								transforms[j].unit +
								") ";
						} else {
							changeStrs[transforms[j].property] =
								"blur(" +
								changeSet.newValue +
								transforms[j].unit +
								")";
						}
						if (changeSet.newValue != changeSet.target) {
							changed = true;
						}
					}
				}
				for (change in changeStrs) {
					item.elem.style[change] = changeStrs[change];
				}
			}
			if (changed == true) {
				window.requestAnimationFrame(ReactiveListener.render);
			}
		}

		//window.requestAnimationFrame(ReactiveListener.render);
		// TODO:  Do we ever want to not be requesting?  Probably...
	};

	ReactiveListener.start = function() {
		document.addEventListener(
			"mousemove",
			ReactiveListener.handleMouseMove
		);
	};

	ReactiveListener.stop = function() {
		document.removeEventListener(
			"mousemove",
			ReactiveListener.handleMouseMove
		);
	};
})();

jQuery(function($) {
	/*--------------------------
	 *
	 *  getQueryVariable(var)
	 *
	 *--------------------------*/
	function getQueryVariable(variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] == variable) {
				return pair[1];
			}
		}
		return false;
	}

	/*-------------
	 *
	 *  isMobile
	 *
	 *-------------*/

	var TOUCH = "ontouchstart" in window;

	var isMobile = $(window).innerWidth() <= 1024 ? true : false;

	$(window).smartresize(function() {
		isMobile = $(window).innerWidth() <= 1024 ? true : false;
	});

	/*-------------
	 *
	 *  SCROLL TO
	 *
	 *-------------*/
	$(".scroll-to").on("click", function() {
		var $dis = $(this);
		var scrollto = $(this).attr("data-scroll-to");
		var scrollOffset = $(this).attr("data-scroll-offset")
			? $(this).attr("data-scroll-offset")
			: "0";

		if ($(scrollto).length) {
			var maxTime = isMobile ? 1700 : 2250;
			var pos =
				parseInt($(scrollto).offset().top) + parseInt(scrollOffset);
			var currScroll = $(window).scrollTop();
			var distancia = pos - currScroll;
			if (distancia < 0) {
				distancia = distancia * -1;
			}
			var scrollTime = distancia * 2;
			if (scrollTime > maxTime) {
				scrollTime = maxTime;
			}
			if (scrollTime < 500) {
				scrollTime = 500;
			}
			$("html, body").animate(
				{
					scrollTop: pos
				},
				scrollTime,
				"easeInOutExpo"
			);
		}
	});

	/*--------------------------
	 *
	 *  BOTON HOME
	 *
	 *--------------------------*/
	$(window).load(function() {
		var $opener = $(".link-button-blur");
		if (!$opener.length) {
			return false;
		}
		setTimeout(function() {
			ReactiveListener.add($opener[0], {
				Pointer2d: [
					{
						property: "backdrop-filter",
						unit: "px",
						range: [0, 10],
						maxDist: 750,
						forceMax: false,
						directional: false,
						start: 0,
						relative: "viewport"
					},
					{
						property: "backdrop-filter",
						unit: "px",
						range: [10, 0],
						maxDist: 750,
						forceMax: true,
						directional: false,
						start: 0,
						relative: "viewport"
					}
				]
			});
			ReactiveListener.start();
		}, 500);
	});

	/*--------------------------
	 *
	 *  ACCORDION
	 *
	 *--------------------------*/
	$(".accordion-title").on("click", function(e) {
		var $dis = $(this);
		var $par = $dis.parent(".accordion-item");
		var $content = $dis.siblings(".accordion-content");
		if (TOUCH) {
			$(".accordion-content").slideUp();
			$(".accordion-item").removeClass("is-open");
		}
		if ($par.hasClass("is-open")) {
			// It's opened, so close
			$content.slideUp();
			$par.removeClass("is-open");
		} else {
			// Open
			$content.slideDown();
			$par.addClass("is-open");
		}
	});

	// Open first item at start
	$(document).ready(function() {
		$(".accordion-item.is-open")
			.find(".accordion-content")
			.show();
	});

	/*--------------------------
	 *
	 *  PROJECTS - COSTS
	 *
	 *--------------------------*/
	$(".project-cost-trigger").on("click", function() {
		var $dis = $(this);
		$dis.siblings(".project-cost-item")
			.not(".project-cost-header")
			.fadeToggle();
		$dis.toggleClass("is-active");
	});
	/*--------------------------
	 *
	 *  PROJECTS - LIGHTBOX
	 *
	 *--------------------------*/
	$(document).on("ready", function() {
		var n = 1;
		const imgEl =
			'<span class="project-img-button">Pantalla completa</span>';

		$(".project-img").each(function() {
			var $dis = $(this);
			$dis.attr("data-image-id", n);
			$dis.prepend(imgEl);
			n++;
		});
		$(window).on("load", function() {
			var $lightbox = $(".project-lightbox");
			if ($lightbox.length) {
				$lightbox.insertAfter("#main");
				$(".project-img").on("click", function() {
					var id = $(this).attr("data-image-id");
					if (id) {
						initLightbox(id);
					}
				});

				// Open from header
				$(".lightbox-open-top").on("click", function() {
					initLightbox(1);
				});
			}
		});
	});

	var $lightEl_title = $(".lightbox-bottom .lightbox-title");
	var $lightEl_close = $(
		".lightbox-top .lightbox-close .lightbox-close-button"
	);
	var $lightEl_next = $(".lightbox-nav .lightbox-next");
	var $lightEl_prev = $(".lightbox-nav .lightbox-prev");
	var $lightEl_counter = $(
		".lightbox-top .lightbox-counter span.counter-active"
	);
	var $lightEl_total = $(
		".lightbox-top .lightbox-counter span.counter-total"
	);
	var $lightEl_active = $(".lightbox-image.is-active");

	var $slideNav = $(".lightbox-nav");

	var isListeningSwipe = false;

	// Init lightbox function
	function initLightbox(id) {
		if (!id) return false;
		var $lightbox = $(".project-lightbox");
		var $openEl = $(".lightbox-image-" + id);
		$(".lightbox-image").removeClass("is-active");
		$openEl.addClass("is-active");
		$(".lightbox-image img:not(.lazy-loaded)").each(function() {
			lazySizes.loader.unveil($(this).get(0));
		});
		updateLightbox();
		$lightbox.fadeIn(function() {
			$("body, html").addClass("lightbox-is-active");
			$lightbox.addClass("is-open");
		});

		// Next image
		var lightboxNext = function(e) {
			var $clicked = $(e.target).closest(".lightbox-image"),
				clickedId = $clicked.data("id");
			var nextId = +clickedId + 1;
			if ($clicked.hasClass("is-last")) {
				nextId = 1;
			}
			$(".lightbox-image")
				.removeClass("is-active")
				.siblings(".lightbox-image-" + nextId)
				.addClass("is-active");
			updateLightbox();
		};
		// Next image
		var lightboxNext = function(e) {
			var $clicked = $(".lightbox-image.is-active"),
				clickedId = $clicked.data("id");
			var nextId = +clickedId + 1;
			if ($clicked.hasClass("is-last")) {
				nextId = 1;
			}
			$(".lightbox-image")
				.removeClass("is-active")
				.siblings(".lightbox-image-" + nextId)
				.addClass("is-active");
			updateLightbox();
		};

		var lightboxPrev = function(e) {
			var $clicked = $(".lightbox-image.is-active"),
				clickedId = $clicked.data("id");
			var nextId = +clickedId - 1;
			if ($clicked.hasClass("is-first")) {
				nextId = $(".lightbox-image.is-last").data("id");
			}
			$(".lightbox-image")
				.removeClass("is-active")
				.siblings(".lightbox-image-" + nextId)
				.addClass("is-active");
			updateLightbox();
		};

		// Close
		var closeLightbox = function() {
			$lightbox.fadeOut(function() {
				$(".lightbox-image").off("click", lightboxNext);
				$(".lightbox-close-button").off("click", closeLightbox);
				$("body, html").removeClass("lightbox-is-active");
				$lightbox.removeClass("is-open");
				$lightEl_close.get(0).onkeyup = null;
			});
		};

		// Navigation - Mouse
		if (!TOUCH) {
			$lightEl_next.on("click", lightboxNext);
			$lightEl_prev.on("click", lightboxPrev);
		} else {
			// Navigation - Mobile swipe

			var touchstartX = 0;
			var touchendX = 0;

			// Check if event listener is already on to avoid doubles
			if (!isListeningSwipe) {
				$slideNav.get(0).addEventListener(
					"touchstart",
					function(event) {
						touchstartX = event.changedTouches[0].screenX;
					},
					false
				);

				$slideNav.get(0).addEventListener(
					"touchend",
					function(event) {
						touchendX = event.changedTouches[0].screenX;
						handleSwipe();
					},
					false
				);
				isListeningSwipe = true;
			}
			function handleSwipe() {
				if (touchendX > touchstartX) {
					lightboxPrev();
				} else {
					lightboxNext();
				}
			}
		}

		// Close
		$(".lightbox-close-button").on("click", closeLightbox);
		$(document).on("keyup", function(e) {
			if (e.keyCode === 27) closeLightbox();
		});
	}
	function updateLightbox() {
		$lightEl_active = $(".lightbox-image.is-active");
		if ($lightEl_active.length) {
			var id = $lightEl_active.data("id");
			var tit = $lightEl_active.data("title");
			$lightEl_title.html(tit);
			$lightEl_counter.html(id);
		}
	}

	/*--------------------------
	 *
	 *  BLOCK - LIMIT HEIGHT
	 *  for block-full followed by block-caption
	 *
	 *--------------------------*/
	$(document).ready(function() {
		$(".block-caption").each(function() {
			var $dis = $(this);
			var $prev = $dis.prev(".block");
			if ($prev.hasClass("block-full")) {
				$prev.addClass("block-limit-height");
			}
		});
	});

	/*--------------------------
	 *
	 *  SHARE MENU
	 *
	 *--------------------------*/
	$("body").on("click", ".share-trigger", function() {
		var $dis = $(this);
		var $list = $dis.next(".share-links");
		if ($list.length) {
			$dis.removeClass("text-link-xs share-trigger");
			$list
				.slideToggle()
				.find("li a")
				.addClass("text-link-xs");
		}
	});

	/*--------------------------
	 *
	 *  FORM - CV UPLOAD
	 *
	 *--------------------------*/

	// bytes to size
	function bytesToSize(bytes) {
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes === 0) return "n/a";
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
		if (i === 0) return `${bytes} ${sizes[i]}`;
		return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
	}
	// truncate and add ...
	function truncateString(str, num) {
		if (str.length <= num) {
			return str;
		}
		return str.slice(0, num) + "...";
	}

	var $fileinput = $(".af-field .acf-file-uploader input[type=file]");
	var $filelabel = $fileinput.parents(".acf-basic-uploader");
	var $filehiddeninput;

	// Append remove file button -- disabled
	//$filelabel.append('<div class="file-upload-remove"></div>');

	$(document).ready(function() {
		if ($filelabel.length) {
			$filelabel.attr(
				"data-caption-empty",
				"Arrastra aquí el archivo, o haz click para seleccionar"
			);
			var tempo = $fileinput.attr("name");
			$filehiddeninput = $('[name="' + tempo + '"]').first();
			$filehiddeninput.attr("id", "file-input-hidden");
		}
	});

	// On change
	var max_size = "5242880"; // 5MB
	$fileinput.on("change", function(e) {
		// Add focused class to field
		$(this)
			.parents(".af-field-type-file")
			.addClass("is-used");

		var files = e.currentTarget.files;
		var file = files[0];

		if (typeof file != "undefined" && file.name && file.size) {
			var ext = file.name
				.split(".")
				.pop()
				.toUpperCase();
			var cap =
				truncateString(file.name, 45) +
				" (" +
				ext +
				", " +
				bytesToSize(file.size) +
				")";

			if (file.size <= max_size) {
				$filelabel.attr("data-caption-file", cap);
				$filelabel.attr("drop-valid", "true");
				$filelabel.addClass("is-uploaded");
			} else {
				cap =
					"Archivo no válido, por favor prueba con otro. Tamaño máximo: 5MB";
				$filelabel.attr("data-caption-file", cap);
				$filelabel.attr("drop-valid", "false");
				$filelabel.removeClass("is-uploaded");
			}
		}
	});

	// $("body").on("click", ".file-upload-remove", function() {
	// 	var $visible = $(".af-field .acf-file-uploader input[type=file]");
	// 	var $hidden = $("#file-input-hidden");
	// 	//$visible.replaceWith($visible.val("").clone(true));
	// 	$hidden.replaceWith($hidden.val("").clone(true));
	// });

	// Drag and drop
	$fileinput.on("dragenter dragleave drop", function(e) {
		if (e.type == "drop") {
			$(this)
				.parents(".acf-basic-uploader")
				.attr("drop-active", "dropped");
		} else {
			var bul = e.type == "dragenter" ? "true" : "false";
			$(this)
				.parents(".acf-basic-uploader")
				.attr("drop-active", bul);
		}
	});

	/*--------------------------
	 *
	 *  FORM - ERROR HANDLING
	 *
	 *--------------------------*/
	//$(window).on("load", function() {
	// ----
	// Disable autocomplete
	// ----
	// $('form').each(function () {
	// 	$(this).attr('autocomplete', 'off');
	// });

	// $(".af-submit button").on("click", function() {
	// 	$(".form-errors").empty();
	// });

	// Validation
	//
	// if (typeof acf != "undefined") {
	// 	acf.add_filter("validation_complete", function(json, $form) {
	// 		console.log(json);
	// 		return json;
	// 	});
	// }
	//});

	/*--------------------------
	 *
	 *  HEADER - MOBILE
	 *
	 *--------------------------*/

	const $menuMobile = $(".header-menu-mobile");
	const $menuTrigger = $(".menu-trigger");

	$menuTrigger.on("click", function() {
		menuToggle();
	});

	function menuToggle() {
		if ($menuMobile.hasClass("is-active")) {
			menuClose();
		} else {
			menuOpen();
		}
	}
	function menuOpen() {
		$menuMobile.addClass("is-active");
		$menuTrigger.addClass("is-active");
		$menuMobile.fadeIn(function() {
			$("body, html").addClass("menu-is-active");
		});
	}
	function menuClose() {
		$("body, html").removeClass("menu-is-active");
		$menuMobile.removeClass("is-active");
		$menuTrigger.removeClass("is-active");
		$menuMobile.fadeOut(function() {});
	}

	/*--------------------------
	 *
	 *  ARCHIVE - FILTER
	 *
	 *--------------------------*/
	$(".filter-item").on("click", function() {
		$(this).toggleClass("is-selected");
		refreshFilters();
	});

	$(".tags-reset").on("click", function() {
		$(".filter-item").removeClass("is-selected");
		refreshFilters();
	});

	function refreshFilters() {
		var $selected = $(".filter-item.is-selected");
		if (!$selected.length) {
			$(".tags-reset").addClass("is-disabled");
		} else {
			$(".tags-reset").removeClass("is-disabled");
		}
		$(".archive-grid-item").removeClass("is-hidden is-visible");

		$selected.each(function() {
			var $dis = $(this);
			var filterclass = $dis.attr("data-class");
			$(".archive-grid-item." + filterclass).addClass("is-visible");
			$(".archive-grid-item:not(.is-visible)")
				.not(".is-visible")
				.not("." + filterclass)
				.addClass("is-hidden");
			$(".archive-grid-item.is-visible").removeClass("is-hidden");
		});

		// Position scroll on mobile if it's past the initial grid
		var maxPos = $(".archive-grid").offset().top;
		var actualPos = window.pageYOffset;
		if (actualPos > maxPos) {
			$(window).scrollTop(maxPos - 70);
		}
	}
	/*--------------------------
	 *
	 *  ARCHIVE - IMAGE SIZE TOGGLE
	 *
	 *--------------------------*/
	$("body").on("click", ".toggle-size:not(.is-active)", function() {
		var $dis = $(this);
		var $rest = $dis.siblings(".toggle-size");
		var isLarge = $dis.hasClass("toggle-large");
		var sizeClass = isLarge ? "is-large" : "is-normal";
		var $grid = $(".archive-grid");

		$(window).scrollTop(0);

		$rest.removeClass("is-active");
		$dis.addClass("is-active");
		$grid.removeClass("is-large is-normal").addClass(sizeClass);
		$grid.find(".image.image-ratio").each(function() {
			$(this).get(0).style = isLarge
				? "--aspect-ratio: 16/9;"
				: "--aspect-ratio: 4/3;";
		});
	});

	/*--------------------------
	 *
	 *  PARENT PAGE SECTION NAV
	 *
	 *--------------------------*/
	var currentSection;
	$(document).ready(function() {
		$(".page-parent-section").waypoint(
			function(direction) {
				var previousWaypoint = this.previous();
				var nextWaypoint = this.next();
				if (direction === "down") {
					currentSection = $(this.element).attr("data-section");
					var $sectionSelector = $(".menu-section-" + currentSection);

					if ($sectionSelector.length) {
						$(".menu-section.is-active").removeClass("is-active");
						$sectionSelector.addClass("is-active");
					}
				} else {
					var prevSection =
						null !== previousWaypoint
							? $(previousWaypoint.element).attr("data-section")
							: false;
					if (prevSection) {
						var $prevSectionSel = $(".menu-section-" + prevSection);
						if ($prevSectionSel.length) {
							$(".menu-section.is-active").removeClass(
								"is-active"
							);
							$prevSectionSel.addClass("is-active");
						}
					}
				}
			},
			{
				offset: "50%"
			}
		);
	});

	/*--------------------------
	 *
	 *  TYPEIT
	 *
	 *--------------------------*/
	$(window).on("load", function() {
		var i = 1;
		setTimeout(function() {
			$(".is-typeit").each(function() {
				var $dis = $(this);
				var dis = this;
				$dis.attr("id", "typeit-" + i);
				var typeEl = document.getElementById("typeit-" + i);
				const type = new TypeIt("#typeit-" + i, {
					strings: $(typeEl).data("content")
				}).go();
				$(typeEl).on("click", function() {
					type.reset().go();
				});
				i++;
			});
		}, 1000);
	});

	/*--------------------------
	 *
	 *  EASY DROPDOWN
	 *
	 *--------------------------*/
	$(window).on("load", function() {
		if ($("select").length) {
			$("select").selectric({
				arrowButtonMarkup: '<b class="button"></b>',
				disableOnMobile: false,
				nativeOnMobile: false
			});
		}
	});

	/*--------------------------
	 *
	 *  CHANGE SIZE PROJECT HEADER
	 *
	 *--------------------------*/
	$(document).ready(function() {
		if ($(".project-header-title h2").length) {
			var $el = $(".project-header-title h2");
			var el = $el.get(0);
			function checkProjectHeaderSize() {
				var scrollTop = $(window).scrollTop();
				var headerHeight = +$(".header").innerHeight() + 10;
				if (el.getBoundingClientRect().top < headerHeight) {
					$el.addClass("is-reduced");
					//$(window).off("scroll", checkProjectHeaderSize);
				} else if (scrollTop < 20) {
					$el.removeClass("is-reduced");
				}
			}
			$(window).on("scroll", _.throttle(checkProjectHeaderSize, 100));
		}
	});

	/*--------------------------
	 *
	 *  LOADER + AOS  + PAROLLER
	 *
	 *--------------------------*/

	var ignore_unload = false;

	// Prevent if link has mailto
	$("a[href^=mailto]").on("click", function() {
		ignore_unload = true;
	});

	$(window).on("beforeunload", function(e) {
		if (!ignore_unload) {
			//$("#loader").fadeIn();
			$("#loader").removeClass("is-loaded");
		}
		ignore_unload = false;
	});

	// Remove AOS attributes if mobile
	$(document).ready(function() {
		if (TOUCH) {
			$("[data-aos]").removeAttr("data-aos");
		}
	});
	$(window).on("load", function() {
		if (!$("body").hasClass("wp-admin") && !TOUCH) {
			setTimeout(function() {
				AOS.init({
					duration: 800,
					easing: "easeOutQuart",
					once: true,
					disable: "mobile"
				});
			}, 200);
		}
		setTimeout(function() {
			//$("#loader").fadeOut();
			$("#loader").addClass("is-loaded");
		}, 0);
	});
});
